@import url("https://fonts.googleapis.com/css2?family=Vazirmatn:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Vazirmatn:wght@100..900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  transition: all 0.25s;
  font-family: "Vazirmatn", sans-serif;
  font-weight: 450;
}
.navbar {
  width: 100%;
  height: 80px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 7px 45px #0c0e3c12;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  position: fixed;
  z-index: 4;
}
.navbar2 {
  display: none;
}
.more {
  cursor: pointer;
  display: flex;
}
.morelinks-option {
  width: 210px;
  height: 250px;
  display: grid;
  background-color: white;
  box-shadow: 1px 1px 30px rgba(27, 27, 27, 0.082);
  position: absolute;
  top: 80px;
  left: 19%;
  padding: 25px 0px 25px 0px;
  border-radius: 10px;
  margin: auto;
}
.morelinks {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0%;
  left: 2%;
}
.back-remove{
  background-color: 500px;
  height: 7000px;
  background-color: #06043a;
  position: absolute;
}
.logo {
  margin: 10px 0px 0px 40px;
}
.logo2-part {
  display: none;
}
.links {
  display: flex;
  align-items: center;
  width: 58%;
  margin-left: 0%;
}
.link-more{
  text-decoration: none;
  color: #0c0e3c;
  font-size: 15.8px;
  margin: 5px 0px 5px 0px;
}
.link-more:hover{
  color: #0fad92;
}
.link {
  margin-left: auto;
  margin-right: 0%;
  text-decoration: none;
  color: #0c0e3c;
  font-size: 15.8px;
}
.margin-auto{
  margin: auto;
}
.link:hover {
  color: #0fad92;
}
.search {
  width: 29.4%;
  height: 38px;
  background-color: #f8f9fa;
  border-radius: 50px;
  outline: none;
  border: none;
  margin-left: auto;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0px 5px 0px 5px;
}
.search-btn {
  width: 90px;
  border-radius: 20px;
  padding: 4px 6px;
  background-color: #33ffdd;
  color: #06071dce;
  border: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.search-btn:active {
  background-color: #16e8c5;
}
.input-search {
  padding-right: 8px;
  width: 95%;
  height: 28px;
  text-align: right;
  color: #6b6d8d;
  outline: none;
  border: none;
  background-color: #f8f9fa;
  font-size: 15px;
  color: #0c0e3c;
}
.make-it-center1 {
  display: grid;
  grid-template-columns: 44% 44%;
  margin-left: 10%;
}
.make-it-center {
  display: flex;
  justify-content: center;
}
.two-events-part {
  width: 80%;
}
.one-little-news {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  text-align: right;
  color: white;
  margin: 20px 0.7% 0px 0%;
}
.one-little-news:hover {
  cursor: pointer;
  transition: all 0.5s;
  .one-little-news-title {
    padding: 60px 10px 0px 10px;
  }
  .one-little-news-see-more {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: none;
    margin: 40px 0px 0px 0px;
    cursor: pointer;
    font-size: 13px;
    background-color: #12d3b3;
    border: none;
    color: white;
  }
  .one-little-news-see-more:hover{
    background-color: #0fad92;
    transition: all 0s;
  }
}
.one-little-news-see-more {
  display: none;
}
.one-little-news-see-more1 {
  width: 200px;
  height: 40px;
  margin: 40px 0px 0px 0px;
  cursor: pointer;
  font-size: 13px;
  text-shadow: 2px 2px 10px black;
  transition: all 0.5s;
  background-color: #0a0c43;
  border: none;
  border-radius: 10px;
  color: white;
  box-shadow: 2px 1px 25px rgb(177, 177, 177);
}
.one-little-news-see-more1:hover {
  background-color: black;
  transition: all 0.5s;
}
.one-little-news-title {
  font-size: 16px;
  padding: 100px 10px 0px 10px;
  text-align: center;
}
.one-little-news-p {
  text-align: center;
  margin-top: 15px;
  font-size: 13.2px;
  padding: 0px 45px 0px 45px;
  width: 70%;
}
.one-little-news-see-more {
  width: auto;
  height: auto;
  padding: 5px 30px 5px 30px;
  box-shadow: 2px 2px 20px black;
  background-color: #12d3b3;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  display: none;
  transition: all 0.5s;
}
.one-little-news-image1 {
  background: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0.403)),
    url(./images/news-image.jpg);
  background-size: cover;
  background-position: center;
}
.one-little-news-image2 {
  background: linear-gradient(rgba(0, 0, 0, 0.419), rgba(0, 0, 0, 0.874)),
    url(./images/news-image2.jpg);
  background-size: cover;
  background-position: center;
  margin-left: 2%;
}
.one-little-news-image3 {
  background: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0.403)),
    url(./images/news-image3.jpg);
  background-size: cover;
  background-position: center;
}
.Stock-conference-report {
  margin-top: 80px;
  width: 74%;
  height: 420px;
  border-radius: 20px;
  color: white;
  background: linear-gradient(rgba(37, 37, 37, 0.363), rgba(0, 0, 0, 0.87)),
    url(./images/2150970201.jpg);
  background-position: center;
  background-size: cover;
}
.Stock-conference-report2 {
  margin-top: 80px;
  width: 74%;
  height: 420px;
  border-radius: 3px;
  color: white;
  background: linear-gradient(rgba(37, 37, 37, 0.363), rgba(0, 0, 0, 0.87)),
    url(./images/5562.jpg);
  background-position: center;
  background-size: cover;
}
.see-details-button {
  width: 51%;
  background-color: #12d3b3;
  color: white;
  height: 40px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}
.see-details-button:hover {
  background-color: #13a88f;
}
.Stock-conference-report-info {
  text-align: right;
  margin: 215px 29px 0px 0px;
}
.login {
  width: 80px;
  height: 40px;
  background-color: white;
  border-radius: 3px;
  border: 1.5px #0c0e3c solid;
  color: #0c0e3c;
  transition: all 0.4s;
  cursor: pointer;
}
.login:hover {
  background-color: #0c0e3c;
  color: white;
}
.login2 {
  width: 80px;
  height: 40px;
  background-color: #0c0e3c;
  border-radius: 3px;
  border: 1px #0c0e3c solid;
  color: white;
  margin-right: 4%;
  margin-left: 0.5%;
  transition: all 0.4s;
  cursor: pointer;
}
.login2:hover {
  background-color: white;
  color: #0c0e3c;
  border: 2px #0c0e3c solid;
}
.first-part-home {
  width: 100%;
  height: 712px;
  padding-top: 60px;
  background: linear-gradient(
    rgba(246, 251, 255, 0.156),
    rgba(255, 255, 255, 0)
  );
}
.firstbigtext {
  font-size: 43px;
  color: #0c0e3c;
  font-weight: 800;
  text-shadow: 1px 1px 15px #0c0e3c69;
}
.secondbigtext {
  font-size: 48px;
  color: #0c0e3c;
  font-weight: 800;
  text-shadow: 1px 1px 15px #0c0e3c69;
  padding: 0px 8px 0px 8px;
}
.homefirstbutton {
  width: 340px;
  height: 52px;
  font-size: 20px;
  font-weight: 600;
  color: #10edc5;
  border-radius: 20px;
  border: none;
  background: linear-gradient(to right, #0c0e3c, #0c0e3c, #0c0e3c, #10edc5);
  cursor: pointer;
  box-shadow: 0px 3px 30px rgba(54, 54, 54, 0.36);
  margin-top: 3%;
  transition: all 0.3s;
}
.homefirstbutton:hover {
  background: linear-gradient(to right, #10edc5, #10d7b7, #10d7b7, #0c0e3c);
  color: #0c0e3c;
}
.homefirstbutton:active {
  box-shadow: 1px 2px 15px rgba(147, 147, 147, 0.738);
}
.information {
  display: flex;
  width: 100%;
}
.bigtexts {
  width: 100%;
  text-align: center;
  padding-top: 135px;
}
.fouroption {
  position: absolute;
  text-align: center;
  left: 81%;
  top: 95px;
  z-index: 0;
}
.pol{
  font-size: 25px;
  width: 400px;
}
.option {
  font-size: 15px;
  color: #484a6c;
  cursor: pointer;
  text-decoration: none;
}
.shape {
  text-align: center;
}
.shape2 {
  display: none;
  margin-top: 35px;
  text-align: center;
}
.shape3 {
  display: none;
  text-align: center;
}
.telegramlink {
  width: 190px;
  height: 114px;
  background-color: #fff;
  border: 0.5px solid #bbbbbba1;
  text-align: center;
  align-items: center;
  border-radius: 0px 10px 10px 0px;
  padding: 10px;
  box-shadow: 0px 1px 8px #dadadac5;
  z-index: 5;
  position: fixed;
}
.linktext {
  color: #0c0e3c;
  margin-top: 20px;
  font-size: 14px;
}
.buttonlink {
  margin-top: 10px;
  width: 151px;
  height: 30px;
  background-color: #0c0e3c;
  color: #10d7b7;
  border: none;
  border-radius: 10px;
}
.line {
  margin-top: 60px;
  width: 80%;
  border-bottom: 0.5px solid #cccccca9;
}
.home-explain-page {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 150px 0px 150px 0px;
}
.h-e-part {
  width: 300px;
  height: auto;
  padding: 30px 0px 40px 0px;
  border: 0.1px solid #e2e6e9;
  background-color: #f8f9fa7d;
  border-radius: 5px;
  margin: 0% 1.5% 0% 1.5%;
  text-align: center;
  box-shadow: 2px 10px 25px rgba(215, 215, 215, 0.447);
}
.h-e-image {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-position: center;
  background-size: cover;
  margin: auto;
}
.h-e-title {
  margin: 20px 0px 10px 0px;
  color: #0a0c43;
  font-size: 20px;
  width: 190px;
  font-weight: 600;
}
.h-e-text {
  color: #4f5075;
  font-size: 15.5px;
  width: 75%;
  margin: auto;
}
.h-e-stickers {
  margin-top: 10px;
}
.title {
  font-size: 25px;
  margin: 15px 0px 3px 0px;
  color: #0c0e3c;
}
.free-subscription-member {
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
}
.f-s-part {
  width: 82%;
  height: 380px;
  background: #ffffff;
  border-radius: 8px;
  border: 0.5px solid rgba(193, 193, 193, 0.371);
  box-shadow: 6px 6px 50px rgba(230, 230, 230, 0.544);
  text-align: center;
  margin-bottom: 80px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.f-s-part2 {
  width: 82%;
  height: 440px;
  background: linear-gradient(rgb(0, 0, 0), #0c0e3c);
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(171, 171, 171, 0.159);
  text-align: center;
  margin-bottom: 80px;
  color: white;
  display: none;
  justify-content: center;
  align-items: center;
}
.f-s-infotmation {
  margin-left: 2%;
  width: 100%;
  color: #070824;
}
.f-s-text1 {
  font-size: 28px;
  color: #0c0e3c;
  font-weight: 700;
  text-shadow: 1px 1px 15px #0c0e3c69;
  width: 100%;
}
.f-s-text2 {
  font-size: 28px;
  margin-top: 20px;
  color: #0c0e3c;
  font-weight: 700;
  text-shadow: 1px 1px 15px #0c0e3c69;
  width: 100%;
}
.f-s-button {
  width: 300px;
  height: 50px;
  border-radius: 8px;
  border: 1px white solid;
  margin-top: 30px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 17px;
  font-weight: 500;
  color: white;
  padding: 0px 10px 0px 10px;
  background: linear-gradient(to right, #0c0e3c, #0c0e3c, #0c0e3c, #10edc5);
}
.f-s-button:hover {
  background: linear-gradient(to right, #10edc5, #10d7b7, #10d7b7, #0c0e3c);
  color: #0c0e3c;
}
.illistration {
  height: 100%;
  width: 33%;
  background: url(./images/incut-crypto-transactions-and-trading-on-a-mobile-device.png);
  margin: 4% 7% 0% 0%;
  background-repeat: no-repeat;
}
.h-oursirvices-title {
  font-size: 25.5px;
  font-weight: 600;
  color: #0c0e3c;
  width: 220px;
  background-color: white;
  padding: 0% 0.5%;
}
.h-oursirvices-title-part {
  width: 1%;
  border-top: 0.9px solid #b0b0b054;
  margin-top: 22px;
}
.onesirvice {
  width: 270px;
  height: 117px;
  background-color: transparent;
  border-bottom: 3px solid transparent;
  padding: 20px 10px 20px 10px;
  margin: 1% 1% 1% 1%;
}
.onesirvice:hover {
  background-color: rgb(254, 254, 254);
  box-shadow: 5px 5px 40px rgb(233, 233, 233);
  border-bottom: 3px solid #0fad92;
}
.foursirvices {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  width: auto;
}
.textsirvice {
  color: #737486;
  margin-top: 20px;
  width: 95%;
  margin-left: 4%;
}
.sirvicetitle {
  color: #0f114d;
  font-size: 20px;
  font-weight: 800;
}
.reason {
  margin: 40px 7% 40px 7%;
  color: #06043a;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
.reasons-image {
  margin-top: 30px;
  width: 73%;
  height: 390px;
  background: url(./images/corporate-business-handshake-business-partners.jpg);
  background-position: center;
  background-size: cover;
}
.image {
  width: 35%;
  height: auto;
  background-color: #0c0e3c;
  border-radius: 0px 5px 5px 0px;
}
.oneimages {
  width: 16%;
  height: 150px;
  color: white;
  margin: 0.5%;
  border-radius: 10px;
}
.oneimages h3 {
  margin-top: 40px;
}
.eachimage1 {
  background: linear-gradient(rgba(255, 255, 255, 0), rgb(0, 0, 0)),
    url(./images/one-image-of-for-image1.jpg);
  background-position: center;
  background-size: cover;
}
.eachimage2 {
  background: linear-gradient(rgba(255, 255, 255, 0), rgb(0, 0, 0)), url(./images/one-image-of-for-image2.jpg);
  background-position: center;
  background-size: cover;
}
.eachimage3 {
  background: linear-gradient(rgba(255, 255, 255, 0), rgb(0, 0, 0)),
    url(./images/one-image-of-for-image3.jpg);
  background-position: center;
  background-size: cover;
}
.eachimage4 {
  background: linear-gradient(rgba(255, 255, 255, 0), rgb(0, 0, 0)),
    url(./images/one-image-of-for-image4.jpg);
  background-position: center;
  background-size: cover;
}
.fourimagespart {
  width: 100%;
  height: auto;
  background-color: #a6a9c8;
  padding: 25px 0px 25px 0px;
  margin-top: 200px;
  text-align: center;
}
.fourimages {
  display: flex;
  justify-content: center;
}
.titleofpart {
  text-align: center;
  padding-top: 40px;
  margin: 20px 0px 45px 0px;
  font-size: 23px;
  color: #0c0e3c;
}
.titleofpart2 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #0c0e3c;
  background-color: white;
  width: 270px;
  padding: 0px 15px 0px 15px;
}
.line-titleofpart2 {
  border-bottom: 1px solid rgb(213, 213, 213);
  margin-bottom: 10px;
  width: 30%;
}
.whyyouwork-part {
  border-bottom: 0.1px solid rgb(221, 221, 221);
  margin-bottom: 16px;
  width: 62%;
}
.three-reason{
  display: flex;
  justify-content: center;
  width: 65%;
}
.two-reason{
  display: flex;
  justify-content: center;
  width: 45%;
}
.whyyouwork {
  padding-left: 15px;
  font-size: 24px;
  font-weight: 600;
  color: #0c0e3c;
  background-color: white;
}
.dot {
  font-size: 20px;
  margin-left: 5px;
  color: #0db79a;
}
.reasons {
  width: 100%;
  margin: 190px 0px 220px 0px;
}
.footer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.footer-section {
  width: 85%;
  height: auto;
  border-radius: 10px;
  background-color: rgb(247, 247, 255);
  padding: 60px 20px 80px 20px;
  box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.101);
}
.fonts {
  font-size: 16px;
  color: #0c0e3c;
  margin-top: 20px;
  margin: 20px 0px 0px 35%;
  text-align: left;
}
.fonts2 {
  font-size: 31px;
  color: #0c0e3cd5;
  margin-bottom: 20px;
}
.footer-button {
  width: 221px;
  height: 46px;
  border-radius: 5px;
  border: none;
  background-color: #0fe3bf;
  color: #0c0e3c;
  box-shadow: 1px 1px 29px #0fe3c067;
  cursor: pointer;
  font-weight: 600;
}
.first-part-of-footer {
  width: 80%;
  padding-top: 0px;
}
.footer-option {
  width: 400px;
  text-align: center;
  margin-bottom: 30px;
}
.logo-margin {
  margin-top: 28px;
}
.date {
  color: #0c0e3c;
  font-size: 12.3px;
  text-align: right;
  margin: 5px 0px 15px 10px;
}
.second-section-footer {
  display: flex;
  justify-content: center;
  width: 80%;
}
.newsfooter {
  width: 200px;
  text-align: right;
  color: #0c0e3c;
  font-size: 14px;
  cursor: pointer;
  margin-left: auto;
}
.newsfooter:hover {
  color: #0fad92;
}
.big-word-footer-section {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
}
.big-word-footer {
  padding-bottom: 40px;
  font-size: 120px;
  font-family: "Dancing Script", cursive;
  color: #000000d5;
}
.second-part-footer-title {
  font-size: 20px;
  color: #0c0e3c;
  font-weight: 600;
  text-align: right;
  margin-left: 0px;
}
.call-us-part{
  width: 100%;
  display: flex;
  justify-content: center;
}
.call-us-section{
  display: flex;
  padding-top: "40px";
}
.call-us {
  margin-top: 100px;
}
.call-us-p {
  font-size: 14px;
  color: #06043ad9;
  text-align: center;
}
.four-icons {
  display: flex;
  justify-content: center;
  margin: 20px 0px 50px 0px;
}
.our-services-of-second-footer {
  width: 200px;
  text-align: right;
  color: #0c0e3c;
  margin: 30px 18px 40px 18px;
}
.service {
  color: #0c0e3c;
  margin: 15px 0px 25px 0px;
  font-size: 14px;
}
.service:hover {
  color: #0fad92;
  cursor: pointer;
}
.call-title {
  color: #0c0e3c;
  border-right: 3.5px #0fad92 solid;
  padding-right: 10px;
  margin: 26px 0px 5px 0px;
  font-size: 14px;
}
.call-infmtn {
  color: #07082491;
  font-size: 14px;
}
.options-in-menu {
  display: none;
}
.image-of-explain-part {
  margin-top: 10px;
  width: 19%;
}
.weblog-page {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  height: 500px;
  margin-bottom: 180px;
}
.weblog-image {
  width: 100%;
  background-color: #06043a;
  height: 230px;
  border-radius: 10px 10px 0px 0px;
}
.weblog {
  text-align: center;
  width: 34%;
  height: 550px;
  margin: 2%;
  background-color: #fcfcfd;
  box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.199);
  border-radius: 10px;
}
.address-of-weblog {
  font-weight: 600;
  font-size: 13px;
  margin: 0px 0px 10px 0px;
}
.weblog-title {
  font-size: 20px;
  text-align: center;
  width: 70%;
  margin: 10px 0px 20px 0px;
  font-weight: 600;
}
.weblog-button {
  background-color: #0c0e3c;
  color: #0fe3bf;
  width: 30%;
  height: 36px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.392);
  border-radius: 10px;
  cursor: pointer;
  border: none;
  margin-top: 20px;
}
.weblog-button:hover {
  background-color: #0a0f75;
}
.weblog-p {
  color: #04043498;
  width: 70%;
  text-align: center;
}

/* market news*/
.news-section {
  width: 100%;
  height: 700px;
  padding-top: 60px;
  display: flex;
  justify-content: center;
}
.market-news {
  width: 600px;
  height: 490px;
  padding-bottom: 20px;
  border-radius: 30px;
  box-shadow: 3px 3px 40px rgba(113, 113, 113, 0.107);
  margin-top: 70px;
  text-align: center;
  border: 1px solid rgb(225, 225, 225);
}
.news-image{
  width: 100%;
  height: 240px;
  border-radius: 30px 30px 0px 0px;
  background-color: #06043a;
}
.news-title{
  margin: 25px 0px 10px 0px;
}
.this-lock {
  color: rgb(162, 162, 162);
  margin-top: 15px;
}

/*investment*/
.investment{
  width: 100%;
  height: auto;
  padding-bottom: 80px;
  padding-top: 150px;
  background-color: white;
  display: flex;
  justify-content: center;
}
.investment-services{
  width: 60%;
  background-color: transparent;
  height: auto;
}
.ourservices-investment{
  width: 20%;
  height: 450px;
  background-color: #0c0e3c;
  color: white;
  text-align: center;
  border-radius: 8px;
  margin-top: 30px;
}
.ourservices-investment-title{
  font-size: 19px;
  margin: 20px 0px 20px 0px;
}
.ourservices-investment-service{
  margin: 9.5px 0px 9.5px 0px;
  cursor: pointer;
  font-size: 14.8px;
}
.ourservices-investment-service:hover{
  color: #10edc5;
}
.white{
  color: white;
}
.title-of-investment-page{
  margin-bottom: 40px;
}
.four-reason-in-invetment-page{
  width: 100%;
  background-color: transparent;
  height: auto;
  display: grid;
  grid-template-columns: 27% 27%;
  margin: 50px 0px 80px 21.5%;
}
.title-reason-investment{
  font-size: 20px;
  font-weight: 600;
  color: #0c0e3c;
}
.text-reason-investment{
  font-size: 15px;
  width: 190px;
  margin-left: auto;
  color: #0c0e3c99;
}
.prices-in-invetment-page{
  width: 55%;
  height: auto;
  background-color: #FCFAFF;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.089);
  padding: 25px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
}
.price-title{
  font-weight: 500;
  font-size: 14px;
  color: #0c0e3c;
  text-align: right;
  margin-left: auto;
}
.price{
  font-weight: 300;
  margin-bottom: 15px;
}

/*call page*/

.call-page{
  width: 100%;
  padding: 150px 0px 200px 0px;
  display: flex;
  margin-left: 11.5%;
}
.call-info{
  width: 25%;
  height: 280px;
  background-color: #FCFCFD;
  text-align: right;
  margin-right: 20px;
  box-shadow: 1px 1px 20px rgba(214, 214, 214, 0.415);
  border-radius: 10px;
  border: 1px solid whitesmoke;
  padding: 40px 10px 45px 10px;
}
.call-link-part{
  margin: 5px 0px 5px 0px;
  color: #0c0e3c;
  cursor: pointer;
}
.call-link-part:hover{
  color: #13b597;
}
.info-call-page{
  width: 90%;
  margin: 10px 0px 0px 0px;
}
.call-title-page {
  font-size: 20px;
  font-weight: 600;
  border-bottom: 3.1px solid #10edc5;
  width: 120px;
  margin: 5px auto 35px auto;
  padding-bottom: 10px;
}
.three-links-call{
  text-align: center;
  margin-top: 35px;
  margin-left: 40px;
  display: grid;
}
.call-form{
  width: 35%;
  height: auto;
  text-align: center;
  padding: 40px 10px 40px 10px;
  background-color: #FCFCFD;
  box-shadow: 1px 1px 20px rgba(214, 214, 214, 0.415);
  border-radius: 10px;
}
.form-title{
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  color: #0c0e3c;
}
.form-p{
  width: 75%;
  text-align: center;
  margin: 10px auto 30px auto;
  font-size: 14px;
  color: #0c0e3c;
}
.two-up-form{
  margin: 5px;
  width: 220px;
  padding: 8px 5px 8px 12px;
  border-radius: 7px;
  border: 1px solid rgb(236, 236, 236);
  outline: none;
  font-size: 15px;
  font-weight: 300;
}
.message-input{
  height: 150px;
}
.form-input{
  width: 470px;
  padding: 8px 5px 8px 12px;
  border-radius: 7px;
  border: 1px solid rgb(236, 236, 236);
  outline: none;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 300;
}
.check-box{
  font-size: 12.5px;
  display: flex;
  margin: 30px 0px 10px 5.1%;
}
.check-box input{
  margin-left: 10px;
}
.form-error{
  font-size: 12px;
  color: red;
}
.submit{
  background-color: #0c0e3c;
  color: white;
  width: 73.5%;
  height: 40px;
  border-radius: 5px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 15px rgb(206, 206, 206);
  font-size: 15px;
}
.submit:hover{
  background-color: #151870;
}
.submit:active{
  background-color: #0c0e3c;
}

@media only screen and (max-width: 1440px) {

}
@media only screen and (max-width: 1310px) {
  .link {
    margin-right: 2%;
    font-size: 14px;
  }
  .morelinks-option {
    left: 16%;
  }
  .h-oursirvices-title-part {
    width: 1%;
  }
  .h-e-part {
    height: 240px;
  }
  .h-e-title {
    font-size: 18px;
  }
  .h-e-text {
    font-size: 14.5px;
  }
  .h-e-stickers {
    margin-top: 10px;
  }
  .f-s-part {
    height: 350px;
  }
  .f-s-infotmation {
    margin-right: 50px;
  }
}
@media only screen and (max-width: 1100px) {
  .login2 {
    background-color: #0fe3bf;
    color: #0c0e3c;
    border: none;
  }
  .morelinks-option {
    left: 15%;
  }
  .search-btn {
    padding-right: 10px;
  }
  .home-explain-page {
    display: grid;
    justify-content: center;
    grid-template-columns: 42% 42%;
    margin: 150px 0px 250px 2%;
  }
  .logo2 {
    margin-bottom: 0px;
  }
  .h-e-part {
    width: 90%;
    height: 245px;
    margin-bottom: 10px;
    padding-bottom: 15px;
  }
  .h-e-title {
    margin: 10px 0px 5px 0px;
    font-size: 18px;
  }
  .h-e-text {
    color: #4f5075;
    font-size: 14.5px;
  }
  .h-e-stickers {
    margin-top: 10px;
  }
  .f-s-part {
    width: 88%;
    height: 390px;
    margin-top: 90px;
  }
  .one-little-news-p {
    padding-top: 4%;
    font-size: 12.2px;
  }
  .Stock-conference-report {
    width: 84.15%;
    border-radius: 5px;
  }
  .sirvicetitle {
    font-size: 18.5px;
  }
  .fouroption {
    display: none;
  }
  .foursirvices {
    display: grid;
    grid-template-columns: 40% 40%;
    margin-left: 7%;
  }
  .onesirvice {
    width: 75%;
    height: 117px;
  }
  .textsirvice {
    color: #84859c;
    margin-top: 20px;
  }
  .sirvicetitle {
    color: #0c0e3c;
    font-size: 21.5px;
  }
  .bigtexts {
    width: 100%;
    text-align: center;
  }
  .homefirstbutton {
    margin-top: 3%;
  }
  .oneimages {
    width: 19%;
  }
  .shape {
    display: none;
    text-align: center;
  }
  .shape2 {
    display: flex;
    justify-content: center;
  }
  .weblog {
    width: 40%;
    height: 530px;
  }
  .weblog-title {
    font-size: 17px;
    font-weight: 600;
  }
  .weblog-button {
    width: 35%;
    height: 38px;
  }
  .weblog-p {
    color: #04043498;
    width: 70%;
    text-align: center;
    font-size: 14px;
  }
  .market-news {
    width: 72%;
  }
  .this-lock {
    font-size: 14px;
  }
}
@media only screen and (max-width: 990px){
  .login{
    margin-right: 3%;
  }
  .links-in-little-version{
    display: flex;
    margin-right: 5%;
  }
  .link {
    margin-top: 2.5px;
    margin-right: 4%;
    font-size: 17px;
  }
  .f-s-text1{
    font-size: 25px;
  }
  .f-s-text2{
    font-size: 25px;
  }
  .navbar {
    display: none;
  }
  .navbar2 {
    height: 65px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "YekanBakh-Medium";
    box-shadow: 1px 7px 45px #0c0e3c29;
    background-color: rgb(255, 255, 255);
    z-index: 4;
  }
  .options-in-menu {
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid rgb(226, 226, 226);
    position: fixed;
    display: grid;
    z-index: 1;
    border-radius: 0px 0px 0px 10px;
    padding-top: 50px;
    text-align: right;
    padding-right: 30px;
  }
  .options-in-menu-remove {
    width: 100%;
    height: 100%;
    background-color: #00000089;
    backdrop-filter: blur(1.8px);
    position: fixed;
    display: flex;
    justify-content: right;
    z-index: 1;
  }
  .Account {
    font-size: 14px;
    color: #06043a;
    margin-right: 50px;
  }
  .Account:hover{
    color: #0fad92;
  }
  .aboutus {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: 20px;
  }
  .button-down-menu {
    margin: 17% 8% 13% 8%;
  }
  .threelinkmenu {
    color: #0c0e3c;
    font-size: 14px;
    text-decoration: none;
  }
  .hide-button-in-menu {
    font-size: 17.5px;
    cursor: pointer;
    color: #0c0e3c;
    position: absolute;
    top: 20px;
    left: 5%;
  }
  .hide-button-in-menu:hover {
    color: #0fe3bf;
  }
  .menu {
    display: flex;
    justify-content: right;
    transition: all 0.5s;
    z-index: 4;
  }
  .menu-icon {
    margin: 8px 1% 0px 15px;
    cursor: pointer;
  }
  .three-reason{
    width: 80%;
  }
  .two-reason{
    width: 60%;
  }
  .logo2-part {
    background-color: #0c0e3c;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 4;
  }
  .search{
    width: 60%;
  }
  .one-little-news:hover {
    .one-little-news-title {
      padding: 30px 10px 0px 10px;
    }
  }
  .market-news {
    margin-top: 130px;
  }
}
@media only screen and (max-width: 823px) {
  .f-s-part {
    width: 92%;
  }
  .onesirvice {
    height: 117px;
  }
  .textsirvice {
    margin-top: 20px;
  }
  .sirvicetitle {
    font-size: 21.5px;
  }
  .titleofpart2 {
    text-align: center;
    font-size: 28px;
    color: #0c0e3c;
    background-color: white;
    width: 270px;
    padding: 0px 1% 0px 1%;
  }
  .line-titleofpart2 {
    width: 23%;
  }
  .titleofpart2 {
    font-size: 23.5px;
    width: 250px;
    padding: 0px 1% 0px 1%;
  }
  .line-titleofpart2 {
    width: 23%;
  }
  .weblog-page {
    height: auto;
    margin-bottom: 180px;
  }
  .weblog {
    width: 85%;
    height: 510px;
  }
  .fonts2 {
    font-size: 26px;
  }
  .big-word-footer{
    font-size: 95px;
  }
  .footer-section{
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .home-explain-page {
    display: grid;
    grid-template-columns: 48% 48%;
  }
  .fourimages {
    margin: 0% 5%;
  }
  .titleofpart2 {
    font-size: 24px;
  }
  .h-oursirvices-title {
    font-size: 21.5px;
    width: 210px;
  }
  .h-oursirvices-title-part {
    width: 19%;
  }
  .little-font{
    font-size: 12px;
  }
  .little-font-title{
    font-size: 16px;
  }
  .one-little-news-image2 {
  margin-left: 0%;
  }
  .our-services-of-second-footer {
    width: 30%;
    margin: 30px 40px 40px 0px;
  }
  .second-part-footer-title{
    width: 35%;
    text-align: right;
    margin-right: 15px;
  }
  .date {
    color: #0c0e3c;
    font-size: 12.3px;
    margin: 5px 0px 23px 40px;
  }
  .make-it-center1 {
    grid-template-columns: 89%;
  }
  .market-news {
    width: 500px;
  }
}
@media only screen and (max-width: 730px) {
  .logo {
    margin: 10px 60px 0px 75%;
  }
  .f-s-part {
    height: 300px;
  }
  .textsirvice {
    font-size: 14px;
  }
  .sirvicetitle {
    font-size: 18px;
  }
  .tworeason {
    display: flex;
  }
  .threereason{
    display: grid;
    grid-template-columns: 32% 32% 32%;
  }
  .whyyouwork {
    font-size: 20px;
  }
  .titleofpart {
    font-size: 21px;
  }
  .oneimages {
    width: 24%;
  }
  .titleofpart2 {
    font-size: 22px;
    width: 250px;
    padding: 0px 0% 0px 0%;
  }
  .line-titleofpart2 {
    width: 20%;
  }
}
@media only screen and (max-width: 645px) {
  .options-in-menu {
    width: 73%;
  }
  .logo {
    margin: 10px 60px 0px 62%;
  }
  .f-s-infotmation {
    margin-right: 0%;
  }
  .illistration {
    width: 100%;
  }
  .sirvicetitle {
    font-size: 16.5;
  }
  .textsirvice {
    font-size: 12px;
  }
  .h-oursirvices-title {
    font-size: 20px;
  }
  .fourimages {
    margin: 2%;
  }
  .login2 {
    padding-bottom: 4px;
  }
  .whyyouwork-part {
    width: 40%;
  }
  .illistration {
    margin-top: 65px;
  }
  .titleofpart2 {
    width: 240px;
  }
  .line-titleofpart2 {
    width: 16%;
  }
  .weblog-page {
    display: grid;
    justify-content: right;
    height: auto;
    margin-bottom: 180px;
  }
  .weblog {
    width: 80%;
    height: 520px;
    margin-bottom: 40px;
  }
  .weblog-title {
    width: 70%;
  }
  .market-news {
    width: 79%;
  }
}
@media only screen and (max-width: 600px) {
  .h-e-part {
    height: 230px;
  }
  .h-e-title {
    font-size: 17.5;
  }
  .h-e-text {
    font-size: 14px;
  }
  .reason {
    font-size: 14px;
  }
  .three-reason{
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
  }
  .two-reason{
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
  }
  .oneimages {
    height: 130px;
  }
  .foursirvices {
    grid-template-columns: 80%;
    margin-left: 8.2%;
  }
  .call-us-section{
    display: grid;
    grid-template-columns: 55% 55%;
  }
  .second-part-footer-title{
    width: 70%;
  }
  .our-services-of-second-footer {
    width: 65%;
  }
  .little-version-call-info{
    width: 168%;
  }
}
@media only screen and (max-width: 500px) {
  .menu-icon {
    margin: 8px 10% 0px auto;
  }
  .f-s-text1{
    font-size: 20px;
  }
  .f-s-text2{
    font-size: 20px;
  }
  .firstbigtext{
    font-size: 37px;
    margin-top: 40px;
  }
  .secondbigtext{
    font-size: 40px;
  }
  .big-word-footer{
    font-size: 80px;
  }
  .homefirstbutton{
    margin-top: 20px;
    width: 280px;
    font-size: 16px;
  }
  .weblog-page {
    margin-bottom: 180px;
  }
  .weblog {
    width: 89%;
    height: 510px;
  }
  .weblog-title {
    width: 70%;
    font-size: 16px;
  }
  .weblog-p {
    width: 82%;
    font-size: 13px;
  }
  .home-explain-page {
    grid-template-columns: 80%;
  }
  .this-lock {
    color: rgb(162, 162, 162);
    width: 200px;
    margin: 20px auto auto auto;
  }
  .fourimages {
    display: grid;
    grid-template-columns: 42% 42%;
  }
  .oneimages{
    width: 98%;
  }
  .search{
    position: absolute;
    width: 50%;
    top: 135px;
    left: 44%;
    box-shadow: 1px 1px 15px rgb(232, 232, 232);
  }
  .Stock-conference-report-info {
    text-align: right;
    margin: 175px 29px 0px 0px;
  }
}
@media only screen and (max-width: 430px) {
  .firstbigtext {
    font-size: 28px;
  }
  .secondbigtext {
    font-size: 31px;
  }
  .homefirstbutton{
    width: 260px;
    font-size: 14px;
  }
  .h-e-title {
    font-size: 16.5px;
  }
  .h-e-text {
    font-size: 13.8px;
  }
  .textsirvice {
    font-size: 12px;
  }
  .h-oursirvices-title {
    font-size: 18.8px;
  }
  .sirvicetitle {
    font-size: 17px;
  }
  .whyyouwork-part {
    width: 48%;
  }
  .whyyouwork {
    font-size: 20px;
  }
  .titleofpart {
    font-size: 20px;
  }
  .Account {
    margin-left: 90px;
  }
  .weblog {
    height: 480px;
  }
  .weblog-title {
    width: 90%;
    font-size: 15px;
  }
  .big-word-footer{
    display: none;
  }
  .fonts2 {
    font-size: 21px;
  }
  .f-s-button {
    width: 240px;
    font-size: 14px;
    padding: 0px 8px 0px 8px;
  }
  .market-news{
    width: 95%;
  }
  .call-us-section{
    grid-template-columns: 55% ;
  }
  .second-part-footer-title{
    width: 168%;
    margin-bottom: 40px;
    margin-top: 50px;
  }
  .our-services-of-second-footer {
    width: 168%;
  }
  .little-version-call-info{
    width: 168%;
  }
}
@media only screen and (max-width: 385px) {
  .sirvicetitle {
    font-size: 17px;
  }
  .f-s-part {
    width: 100%;
    padding-bottom: 10px;
    border-radius: 0px;
  }
}
@media only screen and (max-width: 308px){

}